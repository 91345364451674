import dayjs from "dayjs";

export const datetimeToString = (timestamp, format = "MM/DD/YYYY HH:mm", isUTC = false) => {
    if (!timestamp) return "";

    const language = localStorage.getItem("i18nextLng") ? localStorage.getItem("i18nextLng") : "en";
    if (format.includes("MM/DD") && !language.includes("en")) format = format.replace("MM/DD", "DD/MM");
    return isUTC
        ? dayjs(timestamp).utc().locale(language).format(format)
        : dayjs(timestamp).locale(language).format(format);
};

export const getSecuredEmail = (email) => {
    if (!email) return "";
    const arr = email.split("@");
    return `${arr[0][0]}*******@${arr[1]}`;
};

export const getSecuredPhone = (phone) => {
    if (!phone) return "";
    return `+${phone.substr(0, 2)}*******${phone.substr(phone.length - 2, 2)}`;
};

export const getUserName = (userData) => {
    if (!userData) return "";
    let userName =
        `${!!userData.firstname ? userData.firstname : ""} ` +
        `${!!userData.lastname1 ? userData.lastname1 : ""} ` +
        `${!!userData.lastname2 ? userData.lastname2 : ""}`;
    if (!userName || !userName.trim()) userName = userData.email;
    return userName ? userName.trim() : "-";
};

export const getErrorMessage = (e) => {
    return new Promise((resolve) => {
        if (e.message === "Network Error") {
            if (navigator.onLine) {
                fetch("https://www.google.com/", { mode: "no-cors" })
                    .then(() =>
                        resolve(
                            "Disculpa, estamos realizando una mejora en el sistema, hemos incorporando nuevas funcionalidades y unas actualizaciones de seguridad, danos unos minutos más para terminar. Pronto podrás disfrutar de ellas."
                        )
                    )
                    .catch(() =>
                        resolve("Tienes problemas en tu conexión a Internet, verifícalo y vuelve a intentarlo.")
                    );
            } else {
                resolve("Tienes problemas en tu conexión a Internet, verifícalo y vuelve a intentarlo.");
            }
        } else {
            if (e.response) {
                if (e.response.status === 401) {
                    resolve("¡No estás autenticado, inicia sesión nuevamente!");
                } else if (e.response.status === 403) {
                    resolve("¡No tienes permiso!");
                } else if (e.response.status === 404) {
                    resolve("URL no encontrada");
                } else if (e.response.status === 405) {
                    resolve("Solicitud incorrecta");
                } else if (e.response.status === 500) {
                    resolve("Error de API");
                } else {
                    if (e.response?.data?.message) {
                        resolve(e.response.data.message);
                    } else if (e.response?.data?.detail) {
                        resolve(e.response.data.detail);
                    } else {
                        resolve("Solicitud incorrecta");
                    }
                }
            } else {
                resolve(
                    "Disculpa, estamos realizando una mejora en el sistema, hemos incorporando nuevas funcionalidades y unas actualizaciones de seguridad, danos unos minutos más para terminar. Pronto podrás disfrutar de ellas."
                );
            }
        }
    });
};
