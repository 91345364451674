import { combineReducers } from "redux";

import theme from "./theme";
import auth from "./auth";
import ticket from "./ticket";

export default combineReducers({
    theme,
    auth,
    ticket,
});
