import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import dayjs from "dayjs";

import { DownloadOutlined } from "@ant-design/icons";
import { FaUserAlt } from "react-icons/fa";

const props = {
    message: PropTypes.object.isRequired,
    avatar: PropTypes.string,
    direction: PropTypes.string,
};

const MessageItem = ({ message, avatar, direction }) => {
    return (
        <Wrapper className={`${direction || ""}`}>
            <div className="message-avatar">{avatar ? <img src={avatar} alt="Avatar" /> : <FaUserAlt />}</div>
            <div className="message-content">
                <div className="author-name">{message.author_name || "-"}</div>
                {message.type === "text" ? (
                    <div className="message" dangerouslySetInnerHTML={{ __html: message.content }} />
                ) : (
                    <React.Fragment>
                        <div className="message">
                            <span>{message.content}</span>
                            {["png", "jpg"].indexOf(
                                message.content.split(".")[message.content.split(".").length - 1]
                            ) !== -1 && message.url ? (
                                <img src={message.url} alt="Preview" className="image-preview" />
                            ) : null}
                            <div className="align-right">
                                {message.url ? (
                                    <a href={message.url} target="_blank" rel="noreferrer">
                                        <DownloadOutlined />
                                    </a>
                                ) : null}
                            </div>
                        </div>
                    </React.Fragment>
                )}
                <div className="message-info">
                    <div className="datetime">{dayjs(message.timestamp).format("HH:mm")}</div>
                </div>
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    align-items: flex-start;
    padding: 5px 20px;

    &.incoming {
        flex-direction: row;

        .message-content {
            background-color: ${(props) => props.theme.gray200};

            border-top-left-radius: 0;

            .message,
            .message a {
                color: ${(props) => props.theme.inputColor} !important;
            }

            .datetime {
                color: ${(props) => props.theme.inputPSDIcon};
            }
        }
    }

    &.outgoing {
        flex-direction: row-reverse;

        .message-content {
            background-color: #1d99f1;

            border-top-right-radius: 0;

            .message,
            .message a {
                color: #fff !important;
            }

            .author-name {
                color: #f8f8f8;
            }

            .datetime {
                color: #f8f8f8;
            }

            .channel-icon {
                color: #f8f8f8;
            }
        }
    }

    .message-avatar {
        width: 30px;
        height: 30px;

        border-radius: 50%;

        overflow: hidden;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: ${(props) => props.theme.black};

        color: ${(props) => props.theme.white};

        svg {
            fill: ${(props) => props.theme.white};
        }

        img {
            width: 30px;
            border-radius: 50%;
        }
    }
    .message-content {
        max-width: 60%;

        margin: 0 10px;

        padding: 5px 15px;

        border-radius: 8px;

        @media (max-width: 768px) {
            max-width: 75%;
        }

        .author-name {
            font-size: 12px;
        }

        .message {
            font-size: 14px;

            .image-preview {
                width: 100%;
                max-height: 100%;
            }
        }

        .message-info {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .datetime {
                text-align: right;
                font-size: 12px;
            }

            .channel-icon {
                margin-left: 10px;
                font-size: 12px;
            }
        }
    }
`;

MessageItem.propTypes = props;

export default MessageItem;
