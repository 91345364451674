import React from "react";

import Loader from "./Loader";
import styled from "styled-components";

const InitLoader = () => {
    return (
        <Wrapper>
            <Loader />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;

    position: fixed;
    top: 0;
    left: 0;

    background-color: rgba(255, 255, 255, 0.85);

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 9999;
`;

export default InitLoader;
