import { theme } from "antd";

const lightTheme = {
    theme: "light",

    white: "#FFF",
    gray100: "#F3F4F6",
    gray200: "#E5E7EB",
    gray300: "#D1D5DB",
    gray400: "#9CA3AF",
    gray500: "#6B7280",
    gray600: "#4B5563",
    gray700: "#374151",
    gray800: "#1F2937",
    gray900: "#111827",
    black: "#1e2528",

    red: "#FF4D4F",
    orange: "#FE991C",
    pink: "#E45CBE",
    yellow: "#FE991C",
    green: "#108C00",
    blue: "#3C89DA",
    purpleBlue: "#5F4DFB",
    purple: "#800080",
    lightOrange: "#f8e6cc",

    figmaRed: "#EB5757",
    figmaGreen: "#00BC8F",
    figmaYellow: "#E89948",

    // CUSTOMIZE

    contentBg: "#F8F8F8",

    menuBg: "#EFEFEF",
    menuColor: "#4f6068",
    menuActiveColor: "#FE991C",
    menuHoverBg: "rgba(0, 0, 0, .04)",
    menuActiveBg: "#f4f6f7",

    inputColor: "rgba(0, 0, 0, 0.85)",
    inputIcon: "rgba(0, 0, 0, 0.25)",
    inputPSDIcon: "rgba(0, 0, 0, 0.45)",
    inputBorder: "rgba(60, 66, 87, 0.12)",
    inputBoxShadow: "0px 2px 5px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.04)",
};

const darkTheme = {
    theme: "dark",

    black: "#FFF",
    gray900: "#F3F4F6",
    gray800: "#E5E7EB",
    gray700: "#D1D5DB",
    gray600: "#9CA3AF",
    gray500: "#6B7280",
    gray400: "#4B5563",
    gray300: "#374151",
    gray200: "#1F2937",
    gray100: "#111827",
    white: "#1e2528",

    // CUSTOMIZE

    contentBg: "#131719",

    menuBg: "#1e2528",
    menuColor: "#95a6af",
    menuActiveColor: "#FE991C",
    menuHoverBg: "rgba(255, 255, 255, .04)",
    menuActiveBg: "#272f33",

    red: "#FF4D4F",
    orange: "#FE991C",
    pink: "#E45CBE",
    yellow: "yellow",
    green: "#118C52",
    blue: "#3C89DA",
    purpleBlue: "#5F4DFB",
    purple: "#800080",
    lightOrange: "#462e0c",

    figmaRed: "#EB5757",
    figmaGreen: "#00BC8F",
    figmaYellow: "#E89948",

    inputColor: "rgba(255, 255, 255, 0.85)",
    inputIcon: "rgba(255, 255, 255, 0.25)",
    inputPSDIcon: "rgba(255, 255, 255, 0.45)",
    inputBorder: "rgba(200, 200, 220, 0.4)",
    inputBoxShadow: "0px 2px 5px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.04)",
};

const antdThemeLight = {
    algorithm: theme.defaultAlgorithm,
    token: {
        colorPrimary: "#FE991C",
        colorLink: "#17C2D7",
        colorBgLayout: "#F8F8F8",
        colorTextBase: "rgba(0, 0, 0, 0.85)",
        colorText: "rgba(0, 0, 0, 0.85)",
        colorBgContainer: "transparent",

        colorSplit: "rgba(0, 0, 0, 0.1)",

        borderRadius: 8,

        controlHeight: 36,

        fontFamily: "Open sans",
    },
};

const antdThemeDark = {
    algorithm: theme.defaultAlgorithm,
    token: {
        colorPrimary: "#FE991C",
        colorLink: "#17C2D7",
        colorBgLayout: "#131719",
        colorTextBase: "rgba(255, 255, 255, 0.85)",
        colorText: "rgba(255, 255, 255, 0.85)",
        colorBgContainer: "transparent",

        colorSplit: "rgba(255, 255, 255, 0.1)",

        borderRadius: 8,

        controlHeight: 36,

        fontFamily: "Open sans",
    },
};

const styledThemes = {
    light: lightTheme,
    dark: darkTheme,
};

const antdThemes = {
    light: antdThemeLight,
    dark: antdThemeDark,
};

export { styledThemes, antdThemes };
