import styled from "styled-components";

import { rgba } from "polished";

export const Table1 = styled.table`
    width: 100%;

    border-collapse: collapse;

    thead {
        tr {
            &:not(:first-child) {
                th {
                    border-radius: 0 !important;
                }
            }

            th {
                background: ${(props) => rgba(props.theme.orange, 0.25)};
                color: ${(props) => rgba(props.theme.black, 0.8)};
                vertical-align: middle;
                border: none !important;

                padding: 12px;

                text-align: left;
            }
        }
    }

    tbody {
        tr {
            td {
                background: ${(props) => props.theme.white};
                color: ${(props) => rgba(props.theme.black, 0.7)};
                vertical-align: middle;
                border-bottom: 1px ${(props) => rgba(props.theme.black, 0.1)} solid;

                padding: 12px;

                text-align: left;

                .actions {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    white-space: nowrap;
                }
            }

            &.action-tr {
                td {
                    padding-top: 0;
                }
            }

            &:last-child {
                td {
                    border-bottom: none;
                }
            }
        }
    }

    &:not(.with-action-tr) {
        tbody {
            tr {
                &:nth-child(even) {
                    td {
                        background: ${(props) => props.theme.gray100};
                    }
                }
            }
        }
    }

    &.with-action-tr {
        tbody {
            tr {
                &.odd {
                    td {
                        background: ${(props) => props.theme.gray100};
                    }
                }
            }
        }
    }
`;
