import axios from "axios";

import { API_BASE_URL, SUPPORT_PORTAL_AUTH_TOKEN_KEY, SOCKET_API_BASE_URL } from "../../../utils/constants";
import * as actionTypes from "../actionTypes";
import { errorManagementHandler, updateLoading } from "./theme";

export const updateToken = (token) => {
    localStorage.setItem(SUPPORT_PORTAL_AUTH_TOKEN_KEY, token);
    return {
        type: actionTypes.UPDATE_TOKEN,
        token,
    };
};

export const updateSocketToken = (socketToken) => {
    return {
        type: actionTypes.UPDATE_SOCKET_TOKEN,
        socketToken,
    };
};

export const updateUserData = (userData) => {
    return {
        type: actionTypes.UPDATE_USER_DATA,
        userData,
    };
};

export const updateSupports = (supports) => {
    return {
        type: actionTypes.UPDATE_SUPPORTS,
        supports,
    };
};

export const fetchUserData = () => {
    return (dispatch, getState) => {
        const { token } = getState().auth;

        if (!token) {
            dispatch(logout());
        } else {
            dispatch(updateLoading(true));
            axios
                .get(`${API_BASE_URL}/profile/`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Accept-Language": "es",
                    },
                })
                .then((res) => {
                    const userData = res.data;
                    dispatch(updateUserData(userData));
                    dispatch(updateLoading(false));
                })
                .catch((e) => dispatch(errorManagementHandler(e)));
        }
    };
};

export const fetchSocketToken = () => {
    return (dispatch, getState) => {
        const { token } = getState().auth;

        axios
            .get(`${SOCKET_API_BASE_URL}/token/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Accept-Language": "es",
                },
            })
            .then((res) => dispatch(updateSocketToken(res.data.token)))
            .catch((e) => dispatch(errorManagementHandler(e)));
    };
};

export const fetchSupports = () => {
    return (dispatch, getState) => {
        const { token } = getState().auth;

        axios
            .get(`${API_BASE_URL}/supports/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Accept-Language": "es",
                },
            })
            .then((res) => dispatch(updateSupports(res.data)))
            .catch((e) => dispatch(errorManagementHandler(e)));
    };
};

export const logout = () => {
    return () => {
        localStorage.removeItem(SUPPORT_PORTAL_AUTH_TOKEN_KEY);
        window.open(`/login?next=${window.location.pathname}`, "_parent");
    };
};
