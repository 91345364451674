import { updateState } from "../utils";
import * as actionTypes from "../actionTypes";

const initialState = {
    theme: localStorage.getItem("theme") ? localStorage.getItem("theme") : "light",
    sideMenuCollapsed: false,
    loading: false,
    message: {
        type: null,
        content: null,
        extraData: null,
    },
};

const updateTheme = (state, action) => {
    return updateState(state, {
        theme: action.theme,
    });
};

const updateSideMenuCollapsed = (state, action) => {
    return updateState(state, {
        sideMenuCollapsed: action.sideMenuCollapsed,
    });
};

const updateLoading = (state, action) => {
    return updateState(state, {
        loading: action.loading,
    });
};

const updateMessage = (state, action) => {
    return updateState(state, {
        message: action.message,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_THEME:
            return updateTheme(state, action);
        case actionTypes.UPDATE_SIDE_MENU_COLLAPSED:
            return updateSideMenuCollapsed(state, action);
        case actionTypes.UPDATE_LOADING:
            return updateLoading(state, action);
        case actionTypes.UPDATE_MESSAGE:
            return updateMessage(state, action);
        default:
            return state;
    }
};

export default reducer;
