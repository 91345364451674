import React from "react";
import PropTypes from "prop-types";

import { Breadcrumb as AntdBreadcrumb } from "antd";
import { DashboardOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const props = {
    items: PropTypes.array.isRequired,
};

const Breadcrumb = ({ items }) => {
    return (
        <AntdBreadcrumb
            className="mb-2"
            items={[
                {
                    title: (
                        <Link to="/">
                            <DashboardOutlined />
                        </Link>
                    ),
                },
                ...items.map((item) => {
                    if (item.href) return { title: <Link to={item.href}>{item.title}</Link> };
                    return { title: item.title };
                }),
            ]}
        />
    );
};

Breadcrumb.propTypes = props;

export default Breadcrumb;
