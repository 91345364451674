import * as actionTypes from "../actionTypes";

import { getErrorMessage } from "../../../utils/helpers";
import { logout } from "./auth";

export const updateTheme = (theme) => {
    return {
        type: actionTypes.UPDATE_THEME,
        theme,
    };
};

export const updateSideMenuCollapsed = (sideMenuCollapsed) => {
    return {
        type: actionTypes.UPDATE_SIDE_MENU_COLLAPSED,
        sideMenuCollapsed,
    };
};

export const updateLoading = (loading) => {
    return {
        type: actionTypes.UPDATE_LOADING,
        loading,
    };
};

export const updateMessage = (type, content, extraData) => {
    return {
        type: actionTypes.UPDATE_MESSAGE,
        message: {
            type,
            content,
            extraData,
        },
    };
};

export const errorManagementHandler = (e, callback = null) => {
    console.log(e);
    return async (dispatch) => {
        const errorMessage = await getErrorMessage(e);
        dispatch(updateMessage("error", errorMessage));
        if (e?.response?.status === 401) {
            dispatch(logout());
        }
        if (!!callback) callback();
        dispatch(updateLoading(false));
    };
};
