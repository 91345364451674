export const UPDATE_THEME = "UPDATE_THEME";
export const UPDATE_SIDE_MENU_COLLAPSED = "UPDATE_SIDE_MENU_COLLAPSED";
export const UPDATE_LOADING = "UPDATE_LOADING";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";

export const UPDATE_TOKEN = "UPDATE_TOKEN";
export const UPDATE_SOCKET_TOKEN = "UPDATE_SOCKET_TOKEN";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const UPDATE_SUPPORTS = "UPDATE_SUPPORTS";

export const UPDATE_TICKETS = "UPDATE_TICKETS";
export const UPDATE_TICKET_FILTER = "UPDATE_TICKET_FILTER";
