import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { Avatar, Button, Flex, Input, Upload } from "antd";
import { CloseOutlined, LoadingOutlined, PaperClipOutlined, UserOutlined } from "@ant-design/icons";
import { FaPaperPlane } from "react-icons/fa";
import MessageItem from "./MessageItem";

import { getUserName } from "../../utils/helpers";
import { updateMessage } from "../../services/redux/actions/theme";

const props = {
    customer: PropTypes.object.isRequired,
    messages: PropTypes.array.isRequired,
    filter: PropTypes.object.isRequired,
    setFilter: PropTypes.func.isRequired,
    sendMessageHandler: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    showInputBox: PropTypes.bool,
    showUpload: PropTypes.bool,
};

const ChatBox = ({ customer, messages, filter, setFilter, sendMessageHandler, loading, showInputBox, showUpload }) => {
    const dispatch = useDispatch();

    const { userData } = useSelector((state) => state.auth);

    const [message, setMessage] = useState("");
    const [file, setFile] = useState(null);

    const callback = () => {
        setMessage("");
        setFile(null);
    };

    return (
        <Wrapper>
            <div className="header">
                <Flex align="center">
                    <Avatar src={customer.avatar} icon={<UserOutlined />} size={40} />
                    <span className="ml-2">{getUserName(customer)}</span>
                </Flex>
            </div>
            <div
                className="messages scroll-y-without-bar"
                id="messages_box"
                onScroll={(e) => {
                    if (e.target.scrollTop === 0 && messages.length >= filter.page * filter.pageSize) {
                        setFilter({ ...filter, page: filter.page + 1 });
                    }
                }}
            >
                {messages.map((message, index) => {
                    return (
                        <MessageItem
                            key={index}
                            direction={message.author !== userData?.email ? "incoming" : "outgoing"}
                            avatar={message.author_avatar}
                            message={message}
                        />
                    );
                })}
            </div>
            {showInputBox ? (
                <div className="input-box">
                    {showUpload ? (
                        <Upload
                            showUploadList={false}
                            {...{
                                onRemove: () => setFile(null),
                                beforeUpload: (file) => {
                                    if (file.size / 1024 / 1024 > 10) {
                                        dispatch(
                                            updateMessage(
                                                "error",
                                                "File size is too large, file size should be less than 10 MB."
                                            )
                                        );
                                        return false;
                                    }
                                    setFile(file);
                                    return false;
                                },
                                fileList: file ? [file] : [],
                            }}
                        >
                            <div className="color-grey mr-2">
                                <Button icon={<PaperClipOutlined />} shape="circle" className="no-border" />
                            </div>
                        </Upload>
                    ) : null}

                    <Input
                        placeholder="Escribe un mensaje..."
                        value={file ? file.name : message}
                        disabled={file || loading}
                        suffix={file ? <CloseOutlined onClick={() => setFile(null)} /> : null}
                        onChange={(e) => setMessage(e.target.value)}
                        onPressEnter={() => sendMessageHandler(message, file, callback)}
                    />
                    <div className="color-grey ml-2">
                        <Button
                            disabled={loading}
                            icon={loading ? <LoadingOutlined /> : <FaPaperPlane />}
                            shape="circle"
                            className="no-border"
                            onClick={() => sendMessageHandler(message, file, callback)}
                        />
                    </div>
                </div>
            ) : null}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .header {
        width: 100%;
        height: 60px;

        border-bottom: 1px ${(props) => props.theme.inputBorder} solid;

        padding: 10px 15px;

        display: flex;
        align-items: center;
    }

    .messages {
        width: 100%;

        flex-grow: 1;
    }

    .input-box {
        width: 100%;

        padding: 15px;

        border-top: 1px ${(props) => props.theme.inputBorder} solid;

        display: flex;
        align-items: center;
    }
`;

ChatBox.propTypes = props;

export default ChatBox;
