import React, { useEffect } from "react";
import Loadable from "react-loadable";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { ConfigProvider, notification } from "antd";
import { MessageOutlined } from "@ant-design/icons";
import { Loader } from "./components";

import { updateMessage } from "./services/redux/actions/theme";
import { antdThemes, styledThemes } from "./utils/themes";

import es from "antd/locale/es_ES";

const loading = () => (
    <>
        <Loader />
    </>
);

const Login = Loadable({
    loader: () => import("./pages/Login/Login"),
    loading,
});

const MainLayout = Loadable({
    loader: () => import("./layouts/MainLayout/MainLayout"),
    loading,
});

function App() {
    const dispatch = useDispatch();

    const { theme, message } = useSelector((state) => state.theme);

    useEffect(() => {
        if (message.content !== null) {
            if (message.type === "message") {
                notification.open({
                    message: message.extraData,
                    description: message.content,
                    className: theme,
                    icon: <MessageOutlined style={{ color: "#FE991C" }} />,
                    onClose: () => dispatch(updateMessage(null, null)),
                });
            } else {
                notification[message.type]({
                    message: message.type.toUpperCase(),
                    description: message.content,
                    onClose: () => dispatch(updateMessage(null, null)),
                });
            }
        }
    }, [message.content]); // eslint-disable-line

    return (
        <ConfigProvider theme={antdThemes[theme]} locale={es}>
            <ThemeProvider theme={styledThemes[theme]}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route path="/*" element={<MainLayout />} />
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        </ConfigProvider>
    );
}

export default App;
