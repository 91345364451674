import React from "react";
import PropTypes from "prop-types";

import { Tag } from "antd";

const props = {
    value: PropTypes.any,
    enumObj: PropTypes.object.isRequired,
    colorObj: PropTypes.object,
};

const EnumTag = ({ value, enumObj, colorObj }) => {
    return <Tag color={(colorObj || {})[value || null]}>{enumObj[value]}</Tag>;
};

EnumTag.propTypes = props;

export default EnumTag;
