import styled from "styled-components";

import { rgba } from "polished";

export const Table2 = styled.table`
    width: 100%;

    border-collapse: collapse;

    tbody {
        tr {
            td {
                color: ${(props) => rgba(props.theme.black, 0.7)};
                vertical-align: middle;

                padding: 5px 12px 5px 0;

                text-align: left;

                &:first-child {
                    font-weight: 600;
                }
            }
        }
    }
`;
