export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const SOCKET_BASE_URL = process.env.REACT_APP_SOCKET_BASE_URL;
export const SOCKET_API_BASE_URL = API_BASE_URL.replace("/support", "") + "/websocket";
export const MEDIA_BASE_URL = API_BASE_URL.replace("/support", "") + "/media";

export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const FIREBASE_DATABASE_URL = process.env.REACT_APP_FIREBASE_DATABASE_URL;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;

export const FIREBASE_COLLECTION_PREFIX = process.env.REACT_APP_FIREBASE_COLLECTION_PREFIX || "";

/** ------------------- */
// LS KEYS
export const SUPPORT_PORTAL_AUTH_TOKEN_KEY = "_lg_s_auth_token";

/** ------------------- */
// STRING MESSAGES
export const msgFieldRequired = "Este campo es obligatorio";
export const msgFieldInvalid = "Este campo no es válido";
export const msgConfirmPasswordCorrectly = "Confirmar contraseña correctamente";
